<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa' && rol != 'admin-sucursal' && rol != 'admin-depto'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>        
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Empleados inactivos'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        @setFilters="setFilters"
                        @loadModalData="loadModalData"
                        :perPage="[10,25,50,100]"
                    >
                     <template slot="filters">
                        <v-col xs="12" sm="12" md="12">
                            <div class="RangoIngreso">
                                <span >Rango de fechas de baja</span>
                            </div>
                        </v-col>

                       <v-col xs="12" sm="6" md="6" class="py-0">
                            <VuetifyDatePicker
                                    outlined
                                    v-model="fecha_inicio"
                                    label="Fecha inicio"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaInicio"
                                />
                            
                        </v-col>

                        <v-col xs="12" sm="6" md="6" class="py-0">
                            <VuetifyDatePicker
                                    outlined
                                    v-model="fecha_final"
                                    label="Fecha final"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaFinal"
                                />
                        </v-col>

                        <v-col xs="12" sm="12" md="12" class="py-0" >
                            <v-text-field
                            outlined
                            label="Nombre"
                            v-model="nombre"
                            ></v-text-field>
                        </v-col>
                        <v-col xs="12" sm="12" md="12" class="py-0" >
                            <v-text-field
                            outlined
                            label="RFC"
                            v-model="rfc"
                            ></v-text-field>
                        </v-col>
                        <!-- <v-col xs="12" sm="12" md="6" class="py-0" >
                            <v-text-field
                            outlined
                            label="Correo"
                            v-model="correo"
                            ></v-text-field>
                        </v-col> -->

                        <template v-if="rol == 'root'">
                            <v-col xs="12" sm="12" md="12" class="py-0">
                            <v-autocomplete
                                outlined
                                :items="clientes"
                                item-text="nombre"
                                item-value="id"
                                label="Cliente"
                                persistent-hint
                                v-model="cliente_value"
                            ></v-autocomplete>
                            </v-col>
                        </template>

                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                            <v-autocomplete
                                outlined
                                label="Registro Patronal"
                                :items="registroPatronal"
                                item-text="nombre"
                                item-value="id"
                                v-model="registroPatronal_value"
                            >
                            </v-autocomplete>
                        </v-col>

                        <template v-if="rol == 'root' || rol == 'admin'">
                            <v-col xs="12" sm="6" md="6" class="py-0">
                            <v-autocomplete
                                outlined
                                :items="empresas"
                                item-text="nombre"
                                item-value="id"
                                label="Empresa"
                                persistent-hint
                                v-model="empresa_value"
                            ></v-autocomplete>
                            </v-col>
                        </template>

                        <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'">
                            <v-col xs="12" sm="6" md="6" class="py-0">
                            <v-autocomplete
                                outlined
                                :items="sucursales"
                                item-text="nombre"
                                item-value="id"
                                label="Sucursal"
                                persistent-hint
                                v-model="sucursal_value"
                            ></v-autocomplete>
                            </v-col>
                        </template>

                        <template
                            v-if="
                            rol == 'root' ||
                                rol == 'admin' ||
                                rol == 'admin-empresa' ||
                                rol == 'admin-sucursal'
                            "
                        >
                            <v-col xs="12" sm="6" md="6" class="py-0">
                            <v-autocomplete
                                outlined
                                :items="departamentos"
                                item-text="nombre"
                                item-value="id"
                                label="Departamento"
                                persistent-hint
                                v-model="departamento_value"
                            />
                            </v-col>
                        </template>
                        <v-col xs="12" sm="6" md="6" class="py-0">
                            <v-autocomplete
                                outlined
                                :items="itemsTiposEmpleados"
                                item-text="descripcion"
                                item-value="valor"
                                label="Tipo de empleado"
                                v-model="searchTipoEmpleado"
                            />
                        </v-col>
                    </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td style="min-width:119px!important; max-width:121px!important; width:120px;padding-right:4px;">
                                    <div>{{ item.clave }}</div>
                                </td>
                                <td @click="detalleEmpleado(item)" class="cursor-pointer">
                                    <div style="max-width:30rem">
                                        <v-list color="transparent" class="py-0 my-0">
                                            <v-list-item class="py-0 my-0 px-0 nombre_completo">
                                                <v-list-item-avatar class="py-0 my-0">
                                                    <v-img  :src="urlImg+item.foto"></v-img>
                                                </v-list-item-avatar>
                                                <v-list-item-content class="ml-0 py-0 my-0">
                                                    <v-list-item-title class="tblPrincipal text-wrap">{{ item.nombre_completo }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </td>
                                <td>
                                    <div>{{ dateFormat(item.fecha_ingreso) }}</div>
                                </td>
                                <td>
                                    <div>{{ dateFormat(item.fecha_baja) }}</div>
                                </td>
                                <td>
                                    <div class="tblOpciones">
                                       <!--  


                                        <template v-if="item.fecha_cobro_finiquito==null">
                                            <v-tooltip bottom v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa' || rol == 'admin-sucursal'">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn 
                                                        v-on="on" 
                                                        class="botonHover" 
                                                        icon 
                                                        fab 
                                                        dark 
                                                        small 
                                                        @click="(
                                                            item.tipoRegimen.clave == '05' || 
                                                            item.tipoRegimen.clave == '06' ||
                                                            item.tipoRegimen.clave == '07' ||
                                                            item.tipoRegimen.clave == '08' ||
                                                            item.tipoRegimen.clave == '09' ||
                                                            item.tipoRegimen.clave == '10' ||
                                                            item.tipoRegimen.clave == '11'
                                                        )?abrirModalEmpleadoAsimilado('update', item):abrirModalEmpleado('update', item)"
                                                    >
                                                        <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Editar</span>
                                            </v-tooltip>
                                        </template>
                                        
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal(item)">
                                                <v-icon class="tamIconoBoton iconoDelgadoBoton" v-text="'$remove_red_eye_outline'"></v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Motivo de baja</span>
                                        </v-tooltip>
                                        <v-tooltip bottom v-if="rol != 'admin-depto' && rol != 'empleado'">
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModalRestaurar(item)">
                                                <v-icon class="tamIconoBoton iconoDelgadoBoton">history</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Restaurar</span>
                                        </v-tooltip> 
                                        
                                        -->

                                        <v-menu buttom left class="elevation-0 d-inline" content-class="mt-0 menuClassAccionesEmpleado"> 
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    icon 
                                                    fab
                                                    dark
                                                    small
                                                    class="elevation-0"
                                                >
                                                    <template  v-for="(item,valor) in attrs" >
                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                    </template>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <v-list-item
                                                    @click="openModalInfo(item)"
                                                >
                                                    <v-list-item-title>Ver datos del empleado</v-list-item-title>
                                                </v-list-item>
                                                <template>
                                                    <v-list-item
                                                        v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa' || rol == 'admin-sucursal'"
                                                        @click="(item.asimilado)?abrirModalEmpleadoAsimilado('update', item):abrirModalEmpleado('update', item)" 
                                                    >
                                                        <v-list-item-title>Editar empleado</v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                                <v-list-item
                                                    @click="abrirModal(item)"    
                                                >
                                                    <v-list-item-title>Historial del empleado</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    v-if="rol != 'admin-depto' && rol != 'empleado'"
                                                    @click="abrirModalRestaurar(item)"  
                                                >
                                                    <v-list-item-title>Restaurar empleado</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>

                                        <v-tooltip bottom v-if="rol != 'admin-depto' && rol != 'empleado'">
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item.id, item.nombre, item.correo)">
                                                <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template >
                <v-dialog v-model="dialog" scrollable persistent max-width="900px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <perfect-scrollbar style="height:100%;">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ tituloModal }}</h2>
                                    </div>
                                </v-card-title>

                                <v-card-text style="padding-right:0px !important; padding-left:0px !important;">
                                    <div id="padre" v-show="isLoading">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    
                                    <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                        <v-col xs="12" sm="12" md="12" class="pa-0 ma-0 mt-3" >
                                            <data-table2
                                                ref="tableHistorico"
                                                :url="url_historico"
                                                :columns="columns_historico"
                                                :filters="filters_historico"
                                                :showAdvancedFilters="false"
                                                :perPage="[5, 10, 15, 20]"
                                                shortPagination
                                                :modalTable="true"
                                                :emptyTable="'emptyTableWhite'"
                                                :classes="tableClasses"
                                                :idTabla="'tablaHistorico'"
                                            >
                                                <tbody slot="body" slot-scope="{ data }">
                                                    <tr :key="item.id" v-for="item in data">
                                                        <td class="pr-1" style="background-color: #ffffff !important;">
                                                            <div class="pl-0">{{ item.accion }}</div>
                                                        </td>
                                                        <td class="px-1" style="background-color: #ffffff !important;">
                                                            <div class="">{{ item.nombre_tipo_baja }}</div>
                                                        </td>
                                                        <td class="px-1" style="background-color: #ffffff !important; width:150px">
                                                            <div>{{ dateFormat(item.fecha) }}</div>
                                                        </td>
                                                        <td class="px-1" style="background-color: #ffffff !important;">
                                                            <div>{{ item.descripcion }}</div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </data-table2>
                                        </v-col>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end card_accion">
                                    <button class="btnCerrar" @click="cerrarModal()">Cerrar</button>
                                </v-card-actions>
                            </ValidationObserver>
                        </perfect-scrollbar>
                    </v-card>
                </v-dialog>
            </template>

            <template >
                <v-dialog v-model="dialog_restaurar" scrollable persistent max-width="600px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalRestaurar"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <perfect-scrollbar style="height:100%;">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ tituloModal }}</h2>
                                    </div>
                                </v-card-title>

                                <v-card-text class="card_texto">
                                    <div id="padre" v-show="isLoading">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    
                                    <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0" >
                                                <v-text-field
                                                    outlined
                                                    readonly
                                                    v-model="dataEmpleado.nombre_completo"
                                                    label="Nombre"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    rules="required"
                                                    v-model="fechaRestaurar"
                                                    label="Fecha"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="FechaRestaurar"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end card_accion">
                                    <button
                                        class="btnCerrar"
                                        @click="cerrarModal()" 
                                        :disabled="isSaving"
                                    >
                                        Cancelar
                                    </button>
                                    <v-btn
                                        class="btnGuardar"
                                        @click="restaurarEmpleado()" 
                                        :disabled="invalid" 
                                        :loading="isSaving"
                                    >
                                        Restaurar
                                    </v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </perfect-scrollbar>
                    </v-card>
                </v-dialog>
            </template>
             <template> 
                <v-row class="justify-center">
                    <v-dialog v-model="dialog_empleado" scrollable persistent max-width="900px">
                        <v-card>
                            <v-card-text class="pa-0 ma-0"> 
                                    <ModalEmpleado 
                                        :title="tituloEmpleado"
                                        :objEmpleado="datosEmpleado"
                                        :action="accionEmpleado"
                                        @cerrarModal="cerrarModalEmpleado"
                                        :key="'A'+componentKey"
                                    />
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <template> 
                <v-row class="justify-center">
                    <v-dialog v-model="dialogEmpleadoAsimilado" scrollable persistent max-width="900px">
                        <v-card>
                            <v-card-text class="pa-0 ma-0"> 
                                    <ModalEmpleadoAsimilado 
                                        :title="tituloEmpleado"
                                        :objEmpleado="datosEmpleado"
                                        :action="accionEmpleado"
                                        @cerrarModal="cerrarModalEmpleado"
                                        :key="'A'+componentKey"
                                    />
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>

            <template> 
                <v-row class="justify-center">
                    <v-dialog v-model="dialog_info" scrollable persistent max-width="720px">
                        <v-card v-if="showDialoginfo">
                            <v-card-title class="justify-center">
                                <div class="col-lg-12">
                                    <v-btn
                                        @click="cerrarModal()"
                                        small
                                        slot="activator"
                                        icon
                                        text
                                        class="v-btn-cerrar modal-pull-right"
                                    >
                                        <v-icon class="icono-cerrar"></v-icon>
                                    </v-btn>
                                </div>
                                <v-container>
                                <div class="col-lg-12 d-flex" >
                                    <div class="mt-12">
                                        <div class="header">
                                            <h2 class="title">DATOS DEL EMPLEADO</h2>
                                        </div>
                            
                                        <div class="d-flex" >
                                            <v-divider class="divider-blue"></v-divider>
                                            <v-divider class="divider-gray"></v-divider>
                                        </div>
                                        
                                        <div class="row col-lg-12 pb-0 pt-5">
                                            <p class=" py-0 px-0 subtitle mr-2">Nombre:</p>
                                            <p class=" py-0 px-0 text" v-if="objEmpleado != null">{{ objEmpleado.nombre }} {{ objEmpleado.apellido_paterno }} {{ objEmpleado.apellido_materno }}</p>
                                        </div>
                                        <div class="row col-lg-12 py-0 mt-n1">
                                            <p class="py-0 px-0 subtitle mr-2">Clave:</p>
                                            <p class="py-0 px-0 text" v-if="objEmpleado != null">{{ objEmpleado.clave }} </p>
                                        </div>
                                    </div>
                                    <div class="imgMarco">
                                        <img class="imgFoto" :src="fotoModal" />
                                    </div>
                                </div>
                                <div class="col-lg-12 d-flex mt-5">
                                    <v-divider class=" divider-gray skiny"></v-divider>
                                </div>
                                </v-container>
                            </v-card-title>
                            <v-card-text>
                                <perfect-scrollbar style="max-height:100%;">    
                                    <component-info 
                                        :objEmpleado="objEmpleado"
                                        :key="'B'+countInfoKardex"
                                    >
                                    </component-info>
                                </perfect-scrollbar>
                            </v-card-text>

                            <v-card-actions>
                                
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>

        </v-container>
    </div>
</template>

<script>
import Datatable from "@/components/datatable/Datatable.vue";
import empleadosApi from "@/api/empleados";
import clientesApi from "@/api/clientes";
import empresaApi from "@/api/empresas";
import sucursalApi from "@/api/sucursales";
import departamentoApi from "@/api/departamentos";
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import Notify from '@/plugins/notify';
import GetUrlImg from "@/plugins/Urlimagen";
import Datatable2 from "@/components/datatable/Datatable.vue";
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import ModalEmpleado from './Kardex/Modal/ModalKardex.vue';
import ModalEmpleadoAsimilado from './Kardex/Modal/ModalKardexAsimilado.vue';
import ComponentInfo from './Kardex/Modal/ModalKardexInfo.vue';

export default {
    components: {
        VuetifyDatePicker,
        'data-table': Datatable,
        'data-table2': Datatable2,
        ModalEmpleado,
        ModalEmpleadoAsimilado,
        ComponentInfo
    },
    data() {
        return {
            url: "empleados/find",
            columns         : [
                {
                    label: "Clave",
                    name: "clave",
                    filterable: true,
                },
                {
                    label: "Nombre",
                    name: "nombre",
                    filterable: true,
                },
                {
                    label: "Fecha de Ingreso",
                    name: "fecha_ingreso",
                    filterable: true,
                },
                {
                    label: "Fecha de Baja",
                    name: "fecha_baja",
                    filterable: true,
                },
                {
                    label: "Opciones",
                    name: "opciones",
                    align: 'center',
                    filterable: false,
                },
            ],
            filters         : {
                activo          : false,
                baja            : true,
                cliente_id      : null,
                empresa_id      : null,
                sucursal_id     : null,
                departamento_id : null,
                paginate        : true,
                rol_name        : null,
                usuario_id      : null,
                order_by        : 'clave',
                tipo_empleado   : 'ambos'
            },
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            url_historico: "historico_empleado/find",
            columns_historico: [
                 {
                    label: "Tipo de movimiento",
                    name: "accion",
                    filterable: true,
                },
                {
                    label: "Tipo de baja IMSS",
                    name: "tipo_baja_imss_id",
                    filterable: true,
                },
                {
                    label: "Fecha",
                    name: "fecha",
                    filterable: true,
                },
                {
                    label: "Descripción",
                    name: "descripcion",
                    filterable: true,
                },
            ],
            filters_historico: {
                empleado_id: null,
            },
            empleado           : {
                id              : 0,
                nombre          : null,
                fecha_ingreso   : null,
                fecha_baja      : null,
                clave           : null,
                foto            : null,
            },
            userData        : this.$session.get('usuario'),
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            isSaving        : false,
            imageUrl        : "",
            imagen          : "/static/bg/empleado_inactivo.svg",
            nombreImagen    : "",
            datosLogin      : null,
            rol             : null,
            menu_inicio:null,
            menu_final:null,
            EmpleadosFiltros:{},
            fecha_inicio: null,
            fecha_final: null,
            clientes: [],
            empresas: [],
            sucursales: [],
            departamentos: [],
            cliente_value: null,
            empresa_value: null,
            sucursal_value: null,
            departamento_value: null,
            registroPatronal_value: null,
            registroPatronal: [],
            nombre: null,
            correo: null,
            rfc: null,
            tableClasses: {
                table: {
                    "no-box-shadow":true
                },
                "t-head": {
                    "no-radius": true,
                },
            },
            dialog_restaurar    : false,
            dataEmpleado        : null,
            fechaRestaurar      : null,
            datosEmpleado           : null,
            tituloEmpleado          : null,
            accionEmpleado          : null,
            dialog_empleado         : false,
            dialogEmpleadoAsimilado : false,
            componentKey            : 0,


            countInfoKardex         : 0,
            showDialoginfo          : false,
            objEmpleado             : null, 
            fotoModal               : null, 
            dialog_info             : false,
            searchTipoEmpleado      : 'ambos',
            itemsTiposEmpleados     : [
                { valor: 'ambos', descripcion:'Ambos' },
                { valor: 'asalariado', descripcion:'Asalariado' },
                { valor: 'asimilado', descripcion:'Asimilado' }
            ]

        }
    },
    watch: {
        cliente_value: function(val) {
            this.cambiaCliente(val);
            this.getRegistroPatronal();
        },
        empresa_value: function(val) {
            this.cambiaEmpresa(val);
            if(val != null){
                this.mSucursal = true;
            }
            else{
                this.mSucursal = false;
            }
        },
        sucursal_value: function(val) {
            this.cambiaSucursal(val);
            if(val != null){
                this.mDeptos = true;
                this.mReloj =  true;
                this.mPuesto = true;
            }
            else{
                this.mDeptos = false;
                this.mReloj = false;
                this.mPuesto = false;
            }
        },
   
    },
    
    methods: {
        dateFormat(fecha) {
            let fecha2 = fecha.split("-");
            return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
        },
        listar() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;
            this.urlImg = GetUrlImg.getURL();

            
            this.filters.baja = true;
            this.filters.rol_name = this.datosLogin.rol.name;
            this.filters.usuario_id = this.datosLogin.id;

            if (this.rol == "root") {
                this.getClientes();
            }
 
            if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                //this.filters.AND.push({column:'CLIENTE_ID', value:this.cliente_value});
                this.filters.cliente_id = this.cliente_value;
            }

            if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                //this.filters.AND.push({column:'EMPRESA_ID', value:this.empresa_value});
                this.filters.empresa_id = this.empresa_value;
            }

            if(this.rol == "admin-sucursal"){
                this.cliente_value = this.datosLogin.cliente_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
                //this.filters.AND.push({column:'SUCURSAL_ID', value:this.sucursal_value});
                this.filters.sucursal_id = this.sucursal_value;
            }

            if(this.rol == "admin-depto"){
                this.cliente_value = this.datosLogin.cliente_id;
                this.departamento_value = this.datosLogin.departamento_id;
                //this.filters.AND.push({column:'DEPARTAMENTO_ID', value:this.departamento_value});
                this.filters.departamento_id = this.datosLogin.departamento_id;
            }
        },
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },
        abrirModal(item = null){
            this.isLoading  = true;

            this.tituloModal = "Histórico del empleado";
            this.imagen = "/static/bg/empleado_inactivo.svg";
            this.filters_historico.empleado_id = item.id;
            if (this.$refs.tableHistorico) {
                this.$refs.tableHistorico.getData();
            }
            this.isLoading  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        abrirModalRestaurar(item = null){
            this.isLoading  = true;

            this.imagen = '/static/modal/empleadoUpdate.svg';

            this.tituloModal = "Restaurar empleado";
            this.dataEmpleado = item;
            this.isLoading  = false;
            setTimeout(function(){ document.getElementById("btnModalRestaurar").click(); }, 100);
        },
        restaurarEmpleado() {
            let self = this;

            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                if(self.validarFecha(self.fechaRestaurar) == false){
                    Notify.ErrorToast(`Error en el formato de la fecha.`);
                    self.isSaving = false;
                    self.isLoading = false;
                    return;
                }

                let usuario_id = this.datosLogin.id;
                let param = {
                    id: this.dataEmpleado.id,
                    accion: "Reingreso",
                    usuario_id: usuario_id,
                    fecha: this.fechaRestaurar
                };

                empleadosApi.reingresoEmpleado(param).then((response) => {
                    this.$refs.tabla.getData();
                    Notify.Success(
                        "Colaborador restaurado",
                        "El colaborador ha sido restaurado satisfactoriamente."
                    );
                    self.cerrarModal();
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });
            });
        },
        eliminar(id, empleado, email) {
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el colaborador " + empleado + " ?",
                "Eliminar",
                () => {
                    let idEmpleado = id;
                    let accion = "Eliminar";
                    let usuario_id = this.datosLogin.id;
                    let param = {
                        rol_name: this.rol,
                        id: idEmpleado,
                        accion: accion,
                        usuario_id: usuario_id,
                        email: email,
                    };
                    empleadosApi
                        .eliminarEmpleado(param)
                        .then((response) => {
                            
                            this.$refs.tabla.getData();
                            Notify.Success(
                                "Colaborador eliminado",
                                "El colaborador ha sido eliminado satisfactoriamente."
                            );
                        })
                    .catch((err) => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            );
        },
        respuesta_error(err) {
            let error = Object.values(err.response.data.error);
            let leyenda = ``;
            for (var i = 0; i < error.length; i++) {
                leyenda += `* ` + error[i] + `\n`;
            }

            Notify.ErrorToast(leyenda);
        },
        detalleEmpleado(data) {
            this.$session.remove("detalleEmpleado");
            this.$session.set("detalleEmpleado", data);
            this.$router.push("/kardex/detalleEmpleado");
        },
        async getClientes(clientes = null) {
            let param = { activo: true, paginate: false };
            await clientesApi.getClientes(param).then(response => {
                this.clientes = response.data;
                //this.finaliza_parametros();
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes");
            });
        },
        async cambiaCliente(cliente, accion = null) {
            if(cliente == null){
               return;
            }
            let param = { activo: true, paginate: false, cliente_id: cliente };
            await empresaApi.getEmpresas(param).then(response => {
                this.empresas = response.data;
                //this.finaliza_parametros();
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },
        async cambiaEmpresa(empresa, accion = null) {
            if(empresa == null){
                return;
            }
            let parametros = { activo: true, paginate: false, empresa_id: empresa };
            await sucursalApi.getSucursales(parametros).then(response => {
                this.sucursales = response.data;
                //this.finaliza_parametros();
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
            });
        },
        async cambiaSucursal(sucursal, accion = null) {
            if(sucursal == null){
                return;
            }
            let parametros = { activo: true, paginate: false, sucursal_id: sucursal };
            await departamentoApi.getDepartamentos(parametros).then(response => {
                this.departamentos = response.data;
                //this.finaliza_parametros();
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los departamentos.");
            });
        },
        async cambiaDepartamento(departamento, accion = null) {
            let parametros = {
                activo: true,
                paginate: false,
                departamento_id: departamento
            };
        },
        async getRegistroPatronal(){
            if(this.cliente_value == null){
                return;
            }
            let parametros = { activo:true, paginate:false, order:"asc", cliente_id: this.cliente_value }
            await apiRegistroPatronal.find(parametros).then((response) => {
                this.registroPatronal = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Registros Patronales.");
            });
        },
        async loadModalData () {
            await this.resetValues();

            let cliente = null;
            let empresa = null;
            let sucursal = null;
            let depto = null;
            this.searchTipoEmpleado = 'ambos'

            if (this.rol == "root") {
                this.getClientes();
            }
            if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.cambiaCliente(this.datosLogin.cliente_id, this.accion);
                cliente = this.datosLogin.cliente_id;
            }
            if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.cambiaEmpresa(this.datosLogin.empresa_id, this.accion);
                empresa = this.datosLogin.empresa_id;
            }
            if (this.rol == "admin-sucursal") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
                this.cambiaSucursal(this.datosLogin.sucursal_id, this.accion);
                sucursal = this.datosLogin.sucursal_id;
            }
            if (this.rol == "admin-depto") {
                depto = this.datosLogin.departamento_id;
            }
           
        },
        setFilters() {
            this.filters = {
                activo              : false,
                baja                : true,
                paginate            : true,
                include_estado      : true,
                include_ciudad      : true,
                include_cliente     : true,
                include_empresa     : true,
                include_sucursal    : true,
                include_departamento: true,
                tipo_empleado       :'ambos',
            };

            

            let fecha_inicio = this.fecha_inicio;
            let fecha_final = this.fecha_final;
            let cliente = this.cliente_value;
            let empresa = this.empresa_value;
            let sucursal = this.sucursal_value;
            let departamento = this.departamento_value;
            let registroPatronal = this.registroPatronal_value;
            let nombre = this.nombre;
            let rfc = this.rfc;
            let correo = this.correo;

            if (this.rol == "admin") {    
                cliente = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                empresa = this.datosLogin.empresa_id;
            } else if (this.rol == "admin-sucursal") {
                sucursal = this.datosLogin.sucursal_id;
            } else if (this.rol == "admin-depto") {
                departamento = this.datosLogin.departamento_id;
            }
            this.filters.baja = true;
            this.filters.rol_name = this.datosLogin.rol.name;
            this.filters.usuario_id = this.datosLogin.id;
            this.filters.tipo_empleado = this.searchTipoEmpleado;

            if(fecha_inicio != null && fecha_inicio != undefined && fecha_inicio != ""){
                this.filters.min_fecha_baja = fecha_inicio;
            }

            if(fecha_final != null && fecha_final != undefined && fecha_final != ""){
                this.filters.max_fecha_baja = fecha_final;
            }

            if(cliente != null && cliente != undefined && cliente != ""){
                this.filters.cliente_id = cliente;
            }

            if(sucursal != null && sucursal != undefined && sucursal != ""){
                this.filters.sucursal_id = sucursal;
            }

            if(empresa != null && empresa != undefined && empresa != ""){
                this.filters.empresa_id = empresa;
            }

            if(departamento != null && departamento != undefined && departamento != ""){
                this.filters.departamento_id = departamento;
            }

            if(registroPatronal != null && registroPatronal != undefined && registroPatronal != ""){
                this.filters.registro_patronal_id = registroPatronal;
            }

            if(nombre != null && nombre != undefined && nombre != ""){
                this.filters.nombre_completo = nombre;
            }

            if(rfc != null && rfc != undefined && rfc != ""){
                this.filters.rfc = rfc;
            }

            if(correo != null && correo != undefined && correo != ""){
                this.filters.correo = correo;
            }
    },
        cerrarModal() {
            this.dialog         = false;
            this.dialogLayout   = false;
            this.isSaving       = false;
            this.loading_guardar= false;
            this.dialog_restaurar = false;
            this.filters_historico.empleado_id = null;
            this.dialog_info    = false;
            this.resetValues();
        },
        async resetValues() {
            if(this.$refs.FechaInicio != undefined || this.$refs.FechaInicio != null){
                this.$refs.FechaInicio.resetDate();
            }
            if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                this.$refs.FechaFinal.resetDate();
            }
            if(this.$refs.FechaRestaurar != undefined || this.$refs.FechaRestaurar != null){
                this.$refs.FechaRestaurar.resetDate();
            }
            this.fechaRestaurar = null;
            this.$nextTick(() => { 
                if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                }
                if (this.rol == "root") {
                    this.cliente_value = null;
                    this.empresa_value = null;
                    this.sucursal_value = null;
                    this.departamento_value = null;
                    this.empresas = [];
                    this.sucursales = [];
                    this.departamentos = [];
                    this.registroPatronal = [];
                }
    
                if (this.rol == "admin") {
                    this.empresa_value = null;
                    this.sucursal_value = null;
                    this.departamento_value = null;
                    this.sucursales = [];
                    this.departamentos = [];
                }

                if (this.rol == "admin-empresa") {
                    this.sucursal_value = null;
                    this.departamento_value = null;
                    this.departamentos = [];
                }

                if(this.rol == "admin-sucursal"){
                    this.departamento_value = null;
                }

                this.fecha_inicio = null;
                this.fecha_final = null;
                this.nombre = null;
                this.rfc = null;
                this.correo = null;
                this.registroPatronal_value = null;
                this.baja={
                    id          : null,
                    rol_name    : null,
                    accion      :'Baja',
                    motivo      :'',
                    fecha       :'',
                    usuario_id  :null,
                    email       :''
                };
                this.nombreBaja ='';
            });

        },
        /**
         * @method validarFecha Este método valida una fecha
         * @description Valida la fecha que se pase y devuelve true en caso de que la fecha sea verdadero y falso en caso de que la fecha sea incorrecta
         * @param {string} valorFecha formato AAAA-MM-DD
         * @return {boolean} devuelve un true o false.
        */
        validarFecha(valorFecha) {
            try{
                let fecha = valorFecha.split("-");        
                let dia = fecha[2];        
                let mes = fecha[1];        
                let ano = fecha[0];        
                let estado = true;  
                let dmax = 0;
                let diaC = null;
                let mesC =  null;
                let anoC = null;
                
                if ((dia.length == 2) && (mes.length == 2) && (ano.length == 4)) {        
                    switch (parseInt(mes)) {        
                        case 1:dmax = 31;break;        
                        case 2: 
                            if (((ano % 4 == 0) && (ano % 100 != 0 )) || (ano % 400 == 0))
                                dmax = 29; 
                            else 
                                dmax = 28;        
                            break;        
                        case 3:dmax = 31;break;        
                        case 4:dmax = 30;break;        
                        case 5:dmax = 31;break;        
                        case 6:dmax = 30;break;        
                        case 7:dmax = 31;break;        
                        case 8:dmax = 31;break;        
                        case 9:dmax = 30;break;        
                        case 10:dmax = 31;break;       
                        case 11:dmax = 30;break;      
                        case 12:dmax = 31;break;       
                    }  
                    
                    dmax!=""?dmax:dmax=-1;
                    
                    if ((dia >= 1) && (dia <= dmax) && (mes >= 1) && (mes <= 12)) {
                        let i=0;
                        for (i=0 ; i<fecha[2].length; i++) {      
                            diaC = fecha[2].charAt(i).charCodeAt(0);        
                            (!((diaC > 47) && (diaC < 58)))?estado = false:'';       
                            mesC = fecha[1].charAt(i).charCodeAt(0);        
                            (!((mesC > 47) && (mesC < 58)))?estado = false:'';       
                        }  
                    } 
                    else{
                        estado = false;
                    }
                    
                    let j = 0;
                    for(j=0; j < fecha[0].length; j++) {
                        anoC = fecha[0].charAt(j).charCodeAt(0);  
                        (!((anoC > 47) && (anoC < 58)))?estado = false:'';        
                    }
                } 
                else {
                    estado = false;
                }        
                return estado; 
            }catch(err){
                return false;
            }  
        },
        abrirModalEmpleado(accion, item = null){
            this.componentKey += 1;  
            this.datosEmpleado  = item;
            this.tituloEmpleado = accion == "update" ? "Actualizar empleado" : "Registrar empleado";
            this.accionEmpleado = accion;
            this.dialog_empleado=  true;
        },
        abrirModalEmpleadoAsimilado(accion, item = null){
            this.componentKey += 1;  
            this.datosEmpleado  = item;
            this.tituloEmpleado = accion == "update" ? "Actualizar empleado" : "Registrar empleado";
            this.accionEmpleado = accion;
            this.dialogEmpleadoAsimilado=  true;
        },
        cerrarModalEmpleado(data){
            this.datosEmpleado = null;
            if(data == "cancelar"){
                this.dialog_empleado =  false;
                this.dialogEmpleadoAsimilado = false;
            }
            if(data == "guardarCerrar"){
                this.dialog_empleado =  false;
                this.dialogEmpleadoAsimilado = false;
                this.isLoading = true;
                this.$refs.tabla.getData();
            }
        },
        redireccionar(){
            this.$router.push('/dashboard');
        },
        openModalInfo(itemEmpleado) {
            this.countInfoKardex = this.countInfoKardex + 1;
            this.showDialoginfo = true;
            this.objEmpleado = itemEmpleado;
            this.fotoModal = GetUrlImg.getURL() + itemEmpleado.foto;
            this.dialog_info = true;
        },

    },
    created() {
        this.listar();
    },
    mounted(){
        
    },
    updated() { 

        document.querySelector('#tableScroll thead tr th:nth-child(1)').style.paddingRight   = "4px";
        document.querySelector('#tableScroll thead tr th:nth-child(1)').style.width   = "120px";


        let  tablaHistorico = document.querySelector('#tablaHistorico');

        if(tablaHistorico){
            document.querySelector('#tablaHistorico thead tr th:nth-child(1)').style.paddingRight   = "4px"
            document.querySelector('#tablaHistorico thead tr th:nth-child(2)').style.paddingRight   = "4px"
            document.querySelector('#tablaHistorico thead tr th:nth-child(2)').style.paddingLeft    = "4px"
            document.querySelector('#tablaHistorico thead tr th:nth-child(3)').style.paddingRight   = "4px"
            document.querySelector('#tablaHistorico thead tr th:nth-child(3)').style.paddingLeft    = "4px"
            document.querySelector('#tablaHistorico thead tr th:nth-child(4)').style.paddingRight   = "4px"
            document.querySelector('#tablaHistorico thead tr th:nth-child(4)').style.paddingLeft    = "4px"

            document.querySelector('#tablaHistorico thead tr th:nth-child(1) div').style.textAlign  = "initial"
            document.querySelector('#tablaHistorico thead tr th:nth-child(3)').style.width          = "150px"
        }
        
    },
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    #contenedor {
        margin-top: -15px;
    }
    .invertirColor {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(0,0,0,0.54);
    }
    .tamIcono {
        height: 24px !important;
        align-self: center !important;
    }
    .iconoDelgado {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-fill-color: rgba(0,0,0,0.54);
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .cursor-pointer{
      cursor:pointer;
    }

    .divider-blue{
        z-index: 999 !important;
        max-width: 102px;
        border-radius: 50px;
        background-color: #2DB9D1;
        border: 1.5px solid #2DB9D1;
    }
    .divider-gray{
        margin-left:-1px;
        border: 1.5px solid #E0E0E0;
        background:#E0E0E0;
    }
    .skiny{
        margin-top:1px;
        border: .5px solid #E0E0E0;
    }
    .header {
        width: 435px;
        text-align: left !important;
        margin-bottom:15px;
    }
    .title {
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: bold !important;
        font-size: 22px !important;
        line-height: 27px !important;
        letter-spacing: 2px !important;
        color: #333333 !important;
    }
    .imgFoto {
        width: 100%;
        height: 100%;
    }
    .imgMarco{
        width: 191px;
        height: 199px;
        padding:18px;
        border: 3px solid #E0E0E0;
        box-sizing: border-box;
    }
    .cursor-pointer{
        cursor:pointer;
    }

    .subtitle{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #333333;
    }
    .text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #333333;
        text-transform: uppercase;
    }
    .menuClassAccionesEmpleado{
        border-radius: 10px!important;
    }
</style>
